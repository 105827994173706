import React from "react";
import {
  Link as LinkSitecore,
  Image,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import { isNotEmptyObject } from "../../helpers/app/utils/object/index";
import "./styles.scss";
import { generalLinkIsEmpty } from "../../helpers/app/jss/fieldTypes/GeneralLink";

const Footer = (props) => {
  const { fields } = props;
  const {
    heading,
    headingTag,
    headingIsHidden,
    linksheader,
    links,
    icons,
    copy,
  } = fields;
  const currentYear = new Date().getFullYear();

  return (
    <div className="c-Footer wrapper">
      <div className="container container--nospaces">
        {heading && (
          <ComponentHeading
            tag={headingTag}
            field={heading}
            className="c-Footer__heading"
            headingIsHidden={headingIsHidden}
          />
        )}
        <div className="container__inner">
          <>
            <FooterMenu items={links} header={linksheader} />
            <FooterIcons items={icons} />
          </>
        </div>
        {copy && (
              <div className="c-Footer__copy" role="contentinfo">
                <p>
                  &copy; {currentYear} <Text field={copy} tag="span" />
                </p>
              </div>
            )}
      </div>
    </div>
  );
};

// const BtnText = (text) => (
//   <>
//     text
//     <span>
//       <span datatext="text"></span>
//     </span>
//   </>
// );

const FooterItem = (props) => {
  const { link, image } = props.fields;
  let _link = getFieldValue(props.fields, "link");
  _link = !generalLinkIsEmpty(_link);
  const _image = getFieldValue(props.fields, "image");
  const fieldNoFollow = getFieldValue(props.fields, "noFollow");
  const fieldLink = getFieldValue(props.fields, "link");

  if(fieldNoFollow && fieldLink.linktype === "external")
  return (
    _link && (
      <li className={props.className}>
        {isNotEmptyObject(_image) ? 
            <LinkSitecore field={link} rel="nofollow">
              <Image field={image} />
            </LinkSitecore>
            : 
            <LinkSitecore field={link} rel="nofollow"/>
        }
      </li>
    )
  );
  return (
    _link && (
      <li className={props.className}>
        {isNotEmptyObject(_image) ? 
            <LinkSitecore field={link}>
              <Image field={image} />
            </LinkSitecore>
            : 
            <LinkSitecore field={link} />
        }
      </li>
    )
  );
};

const FooterMenu = (props) => {
  return (
    <nav className="c-Footer__menu">
      <ul className="reset">
        {props.header && (
          <Text field={props.header} tag="li" className="c-Footer__menu-item" />
        )}
        {props.items &&
          props.items.map((item) => {
            return (
              <FooterItem
                key={item.id}
                className={"c-Footer__menu-item"}
                {...item}
              />
            );
          })}
      </ul>
    </nav>
  );
};

const FooterIcons = (props) => {
  return (
    <nav className="c-Footer__icons">
      <ul className="c-Footer__icons__list reset">
        {props.items &&
          props.items.map((item) => {
            return (
              <FooterItem
                key={item.id}
                className="c-Footer__icons__item"
                {...item}
              />
            );
          })}
      </ul>
    </nav>
  );
};

export default Footer;
