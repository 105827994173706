var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable import/first */
// node_modules
import React, { useState, useEffect, useRef, useContext } from "react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import { getFieldValue, } from "@sitecore-jss/sitecore-jss-react";
import { withTranslation } from "react-i18next";
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import ContactVisibility from '../../store/ContactVisibility/ContactVisibility';
import { loadExternalScript } from "../../helpers/app/utils/loadExternalScript";
// local_modules
import { dataFetcher } from "../../dataFetcher";
// fragments
import "./styles.scss";
var FormContact = function (props) {
    // settings of form
    var CLASS_IS_FILLED = "is--filled";
    var reCAPTCHA_site_key = '6Lcur88nAAAAAN8uAwj-JvpCgc1lqmG0BihoONiL';
    var receiver = props.fields.receiver;
    var receiverGuid = receiver.id;
    // Labels of fields
    var labelUser = getFieldValue(props, "labelUser");
    var labelCompany = getFieldValue(props, "labelCompany");
    var labelEmail = getFieldValue(props, "labelEmail");
    var labelPhone = getFieldValue(props, "labelPhone");
    var labelMessage = getFieldValue(props, "labelMessage");
    var responseTitle = getFieldValue(props, "responseTitle");
    var responseText = getFieldValue(props, "responseText");
    var buttonLabel = getFieldValue(props, "buttonLabel");
    var disclaimer = props.fields.disclaimer;
    var formHeading = getFieldValue(props, "formHeading");
    var messageSource = typeof window !== 'undefined' ? window.location.href : "";
    var buttonBack = getFieldValue(props, "buttonBack");
    var formBottomInfo = getFieldValue(props, "formBottomInfo");
    var popupIsVisible = useContext(ContactVisibility);
    // Errors messages
    var errorEmpty = "";
    if (props)
        errorEmpty = getFieldValue(props, "errorEmpty");
    var errorWrongEmail = "";
    if (props)
        errorWrongEmail = getFieldValue(props, "errorWrongEmail");
    var errorName = "";
    if (props)
        errorName = getFieldValue(props, "errorName");
    var errorCompany = "";
    if (props)
        errorCompany = getFieldValue(props, "errorCompany");

    var errorSending = getFieldValue(props, "errorSending");
    var form = useRef(null);
    var _a = useState(false), formValid = _a[0], setFormValid = _a[1];
    var _b = useState(false), formSend = _b[0], setFormSend = _b[1];
    var _c = useState(""), userName = _c[0], setUserName = _c[1];
    var _d = useState(""), userValid = _d[0], setUserValid = _d[1];
    var namePattern = /[A-ZĄĆĘŁŃÓŚŹŻa-ząćęłńóśźż]+([ -][A-ZĄĆĘŁŃÓŚŹŻa-ząćęłńóśźż]+)?$/;
    var _e = useState(""), companyName = _e[0], setCompanyName = _e[1];
    var _f = useState(""), companyValid = _f[0], setCompanyValid = _f[1];
    var companyPattern = /^[^\s][\w\W]{1,}$/;
    var _g = useState(""), email = _g[0], setEmail = _g[1];
    var _h = useState(""), emailValid = _h[0], setEmailValid = _h[1];
    var emailPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    var _j = useState(""), phone = _j[0], setPhone = _j[1];
    var _k = useState(""), message = _k[0], setMessage = _k[1];
    var _l = useState(false), formError = _l[0], setFormError = _l[1];
    var _m = useState(true), clauseIsVisible = _m[0], setClauseIsVisible = _m[1];
    var _o = useState(false), formIsSending = _o[0], setFormIsSending = _o[1];
    var _p = useState(true), formIsVisible = _p[0], setFormIsVisible = _p[1];
    useEffect(function () {
    
        // Walidacja userName
        if (!userName || userName.length < 2) {
            setUserValid(errorEmpty);
        } else if (namePattern.test(userName)) {
            setUserValid("");
        } else {
            setUserValid(errorName);
        }

        // Walidacja companyName
        if (!companyName || companyName.length < 2) {
            setCompanyValid(errorEmpty);
        } else if (companyPattern.test(companyName)) {
            setCompanyValid("");
        } else {
            setCompanyValid(errorCompany);
        }
        
        // Formatowanie numeru telefonu
        setPhone(phone.replace(/[^+0-9]/g, ""));

        // Walidacja email
        if (!email) {
            setEmailValid(errorEmpty);
        } else if (emailPattern.test(email)) {
            setEmailValid("");
        } else {
            setEmailValid(errorWrongEmail);
        }

        setFormValid(
            userValid === "" &&
            emailValid === "" &&
            companyValid === ""
        );
    }, [userName, companyName, phone, email, userValid, emailValid, companyValid]);
    
    useEffect(function () {
        loadExternalScript({ src: 'https://www.google.com/recaptcha/api.js?render=' + reCAPTCHA_site_key, async: true })
            .then(function () {
            console.log('recaptcha script is added');
        }, function (e) {
            console.error(e, 'error while adding recaptcha script');
        });
    }, []);
    var grecaptchaProcessing = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(typeof window !== 'undefined' && window.grecaptcha !== undefined)) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, window.grecaptcha.execute(reCAPTCHA_site_key, { action: 'submit' })];
                case 2:
                    token = _a.sent();
                    return [2 /*return*/, {
                            token: token,
                            error: null
                        }];
                case 3:
                    e_1 = _a.sent();
                    return [2 /*return*/, {
                            token: null,
                            error: e_1
                        }];
                case 4: return [3 /*break*/, 6];
                case 5: return [2 /*return*/, { error: new Error('some error with grecaptcha'), token: null, }];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    /*
    const closeForm = () => {
      setFormValid(false);
      setFormSend(false);
      setUserName("");
      setCompanyName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setFormError(false);
      setClauseIsVisible(true);
      const popupContainer = document.getElementById('popupContainer');
      const buttonClose = popupContainer?.querySelector('button.popup-close') as HTMLButtonElement;
      buttonClose.click();
    };
    */
    var pushFormDatalayer = function () {
        if (typeof window !== 'undefined') {
        window.dataLayer.push({
            'event': 'form_submit',
            'page_path': window.location.pathname
        });
        }
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        setClauseIsVisible(false);
        setFormIsSending(true);
        grecaptchaProcessing().then(function (_a) {
            var token = _a.token, error = _a.error;
            if (!error && typeof token == 'string') {
                var formData = {
                    messageSource: messageSource,
                    senderName: userName,
                    senderCompany: companyName,
                    senderEmailAddress: email,
                    senderPhoneNumber: phone,
                    messageText: message,
                    receiverGuid: receiverGuid,
                    Captcha: token
                };
                dataFetcher("/api/postContactForm", formData)
                    .then(function (response) {
                    setFormIsSending(false);
                    setFormIsVisible(false);
                    var res = JSON.parse(JSON.stringify(response.data));
                    switch (res.Success) {
                        case true:
                            setFormSend(true);
                            pushFormDatalayer();
                            break;
                        case false:
                            setFormError(true);
                            break;
                        default:
                            setFormSend(false);
                            break;
                    }
                })
                    .catch(function (err) {
                    setFormIsSending(false);
                    setFormIsVisible(false);
                    console.error(err);
                    setFormError(true);
                });
            }
            else {
                console.error(error);
                setFormError(true);
                setFormIsSending(false);
                setFormIsVisible(false);
            }
        });
    };
    return (React.createElement(PlaceholderComponent, __assign({}, props),
        React.createElement("form", { className: "c-FormContact", onSubmit: handleSubmit, ref: form },
            formIsVisible && React.createElement("div", null,
                formHeading && React.createElement("h2", { className: "c-FormContact__heading" }, formHeading),
                React.createElement("fieldset", { className: "form-row" },
                    React.createElement("div", { className: "form-col-md-50 form-control-wrap" },
                        React.createElement("input", { className: "c-FormContact__input " +
                                (userName.length > 0 ? CLASS_IS_FILLED : ""), id: "senderName", name: "senderName", type: "text", maxLength: "40", value: userName, onChange: function (e) {
                                setUserName(e.target.value);
                            }, placeholder: labelUser }),
                            userValid !== "" && userName.length > 0 && (React.createElement("span", { className: "c-FormContact__error" }, userValid))),
                            React.createElement("div", { className: "form-col-md-50 form-control-wrap" },
                        React.createElement("input", { className: "c-FormContact__input " +
                                (companyName.length > 0 ? CLASS_IS_FILLED : ""), id: "senderCompany", name: "senderCompany", type: "text", maxLength: "50", value: companyName, onChange: function (e) {
                                setCompanyName(e.target.value);
                            }, placeholder: labelCompany }),
                            companyValid !== "" && companyName.length > 0 && (React.createElement("span", { className: "c-FormContact__error" }, companyValid))),
                    React.createElement("div", { className: "form-col-md-50 form-control-wrap" },
                        React.createElement("input", { className: "c-FormContact__input " +
                                (email.length > 0 ? CLASS_IS_FILLED : ""), id: "senderEmailAddress", name: "senderEmailAddress", type: "email", maxLength: "50", value: email, onChange: function (e) {
                                setEmail(e.target.value);
                            }, placeholder: labelEmail }),
                        emailValid !== "" && email.length > 0 && (React.createElement("span", { className: "c-FormContact__error" }, emailValid))),
                    React.createElement("div", { className: "form-col-md-50 form-control-wrap" },
                        React.createElement("input", { className: "c-FormContact__input " +
                                (phone.length > 0 ? CLASS_IS_FILLED : ""), id: "senderPhoneNumber", name: "senderPhoneNumber", type: "text", maxLength: "13", value: phone, onChange: function (e) {
                                setPhone(e.target.value);
                            }, placeholder: labelPhone }))),
                React.createElement("div", { className: "c-FormContact__block" },
                    React.createElement("textarea", { className: "c-FormContact__textarea " +
                            (message.length > 0 ? CLASS_IS_FILLED : ""), id: "messageText", name: "messageText",maxLength: "300", value: message, onChange: function (e) {
                            setMessage(e.target.value);
                        }, placeholder: labelMessage })),
                React.createElement("input", { type: "hidden", name: "messageSource", value: messageSource }),
                React.createElement("button", { className: "c-FormContact__submit", type: "submit", disabled: !formValid }, buttonLabel),
                formBottomInfo && React.createElement("div", { className: "c-FormContact__form-add-info" }, formBottomInfo)),
            formSend && (React.createElement("div", { className: "c-FormContact__typ" },
                React.createElement("div", { className: "c-FormContact__typ-info" },
                    React.createElement("p", { className: "c-FormContact__typ-title form-thx-heading" }, responseTitle),
                    React.createElement("p", { className: "c-FormContact__typ-text" }, responseText),
                    React.createElement("button", { className: "c-FormContact__typ-close", onClick: popupIsVisible.contactPopup.onHidePopup }, buttonBack)))),
            formError && (React.createElement("div", { className: "c-FormContact__typ" },
                React.createElement("div", { className: "c-FormContact__typ-info" },
                    React.createElement("p", { className: "c-FormContact__typ-title form-thx-heading" }, errorSending),
                    React.createElement("button", { className: "c-FormContact__typ-close", onClick: popupIsVisible.contactPopup.onHidePopup }, buttonBack)))),
            formIsSending && React.createElement("div", { className: "loader-container" },
                React.createElement("div", { className: "lds-ellipsis" },
                    React.createElement("div", null),
                    React.createElement("div", null),
                    React.createElement("div", null),
                    React.createElement("div", null)))),
        clauseIsVisible && React.createElement("div", { className: "c-FormContact__clause" },
            React.createElement(RichText, { field: disclaimer, tag: "div", className: "c-FormContact__disclaimer" }))));
};
export default withTranslation()(FormContact);
